import React, { SetStateAction, Dispatch } from "react";
import DialogWrapper from "../dialogs/DialogWrapper";
import { Stack, Typography, Button, Divider } from "@mui/material";
import { FaRegQuestionCircle } from "react-icons/fa";
type Props = {
  msg: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  confirmFn: () => any;
  confirmMsg?: string;
};

const ConfirmationAlert = ({
  msg,
  open,
  setOpen,
  confirmFn,
  confirmMsg = "حذف",
}: Props) => {
  return (
    <DialogWrapper open={open} setOpen={setOpen}>
      <div style={{ width: "fit-content", margin: "auto" }}>
        <FaRegQuestionCircle size={64} />
      </div>
      <Typography variant="h5" mb="8px">
        {msg}
      </Typography>
      <Divider />
      <Stack direction="row" gap="8px" mt="8px">
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            confirmFn();
            setOpen(false);
          }}
        >
          {confirmMsg}
        </Button>
        <Button onClick={() => setOpen(false)}>الغاء</Button>
      </Stack>
    </DialogWrapper>
  );
};

export default ConfirmationAlert;
