import fetching from "../../utils/fetching/fetching";

export const getPublicCategories = (params: string) => {
  return fetching(`/categories${params}`, "GET", {}, null, false)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.status) {
        throw data;
      }
      return data;
    });
};
