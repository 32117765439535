import React from "react";

const NoPermission = () => {
  return (
    <div>
      <h1>لا يوجد لديك الاذن للدخول لهذه الصفحة</h1>
    </div>
  );
};

export default NoPermission;
