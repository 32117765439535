import React from "react";
import { organizerLinks, exhibitorLinks } from "./links";
// mui
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logout from "./Logout";
import { useSelector } from "react-redux";
import { UserSliceState } from "../../types/types";
const SidebarLinks = () => {
  const userData = useSelector((state: UserSliceState) => state.user.userData);
  const links = userData.roles.includes(2)
    ? organizerLinks
    : userData.roles.includes(3)
      ? exhibitorLinks
      : [];
  return (
    <div>
      <List>
        {links.map((link, index) => (
          <ListItem
            key={link.path}
            disablePadding
            sx={{ color: "#f1f1f1", textTransform: "capitalize" }}
          >
            <ListItemButton component={Link} to={link.path}>
              <ListItemIcon
                sx={{ fontSize: "22px", minWidth: "32px", color: "inherit" }}
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <Logout />
      </List>
    </div>
  );
};

export default SidebarLinks;
