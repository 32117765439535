import fetching from "../../utils/fetching/fetching";

export const getStatistics = (token: string) => {
  return fetching(
    `/statistics`,
    "GET",
    { Authorization: `Bearer ${token}` },
    null,
    false
  )
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.status) {
        throw data;
      }
      return data;
    });
};
