import * as yup from "yup";
import { validationMessages as VM } from "../validationMessages";
const maxImgSizeMB = 2;
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20;
export const addEmployeeUserSchema = yup.object().shape({
  img: yup
    .mixed()
    .nullable()
    .test("fileSize", VM.img.too_big, (value: any) =>
      value != null ? value?.size <= maxImgSizeBytes : true
    )
    .test("fileType", VM.img.not_allowed, (value: any) =>
      value != null
        ? ["image/jpg", "image/png", "image/jpeg"].includes(value.type)
        : true
    ),
  name: yup.string().required(VM.field.required),
  email: yup.string().email(VM.email.valid).required(VM.field.required),
  phone: yup.string().required(VM.field.required),
  password: yup.string().oneOf(["password"]),
});
