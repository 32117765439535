import React, { useState } from "react";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { CgMenuRight } from "react-icons/cg";
import SidebarLinks from "./SidebarLinks";
import AppLogo from "./AppLogo";
import packageJson from "../../../package.json";
const drawerWidth = 280;
const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* app bar appears in small screen  */}
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          display: { xs: "flex", md: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", md: "none" } }}
          >
            <CgMenuRight />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            App Name
          </Typography>
        </Toolbar>
      </AppBar>
      {/* side bar in large screen  */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          SlideProps={{
            // in: false,
            direction: "left",
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              paddingTop: "20px",
              backgroundColor: "#27374D",
            },
          }}
        >
          <AppLogo />
          <Typography color="#f1f1f1" textAlign="center">
            V {packageJson.version}
          </Typography>
          <SidebarLinks />
          {/* <Logout /> */}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              paddingTop: "20px",
              width: drawerWidth,
              backgroundColor: "#27374D",
            },
          }}
          open
        >
          <AppLogo />
          <Typography color="#f1f1f1" textAlign="center">
            V {packageJson.version}
          </Typography>
          <SidebarLinks />
          {/* <Logout /> */}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
