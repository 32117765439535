import { RouterProvider } from "react-router-dom";
import Providers from "./components/providers/Providers";
import { router } from "./utils/router/router";

function App() {
  return (
    <div className="App">
      <Providers>
        <RouterProvider router={router} />
      </Providers>
    </div>
  );
}

export default App;
