import { Suspense, lazy } from "react";
// react router
import { createBrowserRouter } from "react-router-dom";
// layouts
import MainLayout from "../../components/layout/MainLayout";
import ProtectedRoutes from "../../components/layout/ProtectedRoutes";
import AllowedTo from "../../components/layout/AllowedTo";
// components
import MagnifyLoader from "../../components/loader/MagnifyLoader";
// pages
import Home from "../../pages/home/Home";
import AddEmployeeUserToEventExhibitor from "../../pages/users/AddEmployeeUserToEventExhibitor";

const Login = lazy(() => import("../../pages/login/Login"));
const Signup = lazy(() => import("../../pages/signup/Signup"));

const Users = lazy(() => import("../../pages/users/Users"));
const AddUser = lazy(() => import("../../pages/users/AddUser"));
const AddCustomerUserToEvent = lazy(
  () => import("../../pages/users/AddCustomerUserToEvent")
);
const AddExhibitorUserToEvent = lazy(
  () => import("../../pages/users/AddExhibitorUserToEvent")
);

const Categories = lazy(() => import("../../pages/categories/Categories"));
const EditCategory = lazy(() => import("../../pages/categories/EditCategory"));

const Banners = lazy(() => import("../../pages/banners/Banners"));
const AddBanner = lazy(() => import("../../pages/banners/AddBanner"));
const EditBanner = lazy(() => import("../../pages/banners/EditBanner"));

const Events = lazy(() => import("../../pages/events/Events"));
const AddEvent = lazy(() => import("../../pages/events/AddEvent"));
const EditEvent = lazy(() => import("../../pages/events/EditEvent"));
const ViewEvent = lazy(() => import("../../pages/events/ViewEvent"));

const EventExhibitors = lazy(
  () => import("../../pages/event-exhibitors/EventExhibitors")
);

const Sponsors = lazy(() => import("../../pages/sponsors/Sponsors"));
const AddSponsor = lazy(() => import("../../pages/sponsors/AddSponsor"));
const EditSponsor = lazy(() => import("../../pages/sponsors/EditSponsor"));

const EventActivities = lazy(
  () => import("../../pages/event-activities/EventActivities")
);
const AddEventActivity = lazy(
  () => import("../../pages/event-activities/AddEventActivity")
);
const EditEventActivity = lazy(
  () => import("../../pages/event-activities/EditEventActivity")
);

const EventSessions = lazy(
  () => import("../../pages/event-sessions/EventSessions")
);
const AddEventSession = lazy(
  () => import("../../pages/event-sessions/AddEventSession")
);
const EditEventSession = lazy(
  () => import("../../pages/event-sessions/EditEventSession")
);

const EventCustomers = lazy(
  () => import("../../pages/event-customers/EventCustomers")
);
const Settings = lazy(() => import("../../pages/settings/Settings"));

const ExhibitorEvents = lazy(
  () => import("../../pages/exhibitor/events/ExhibitorEvents")
);
const AvailableEvents = lazy(
  () => import("../../pages/exhibitor/events/AvailableEvents")
);
const EventCustomersForExhibitor = lazy(
  () =>
    import("../../pages/exhibitor/eventCustomers/EventCustomersForExhibitor")
);

const PushNotification = lazy(
  () => import("../../pages/notification/PushNotification")
);
const Notifications = lazy(
  () => import("../../pages/notification/Notifications")
);

const EventRegistration = lazy(
  () => import("../../pages/public/EventRegistration")
);

const ExhibitorEmployees = lazy(
  () => import("../../pages/employees/ExhibitorEmployees")
);
const AddExhibitorEmployee = lazy(
  () => import("../../pages/employees/AddExhibitorEmployee")
);
const EditExhibitorEmployee = lazy(
  () => import("../../pages/employees/EditExhibitorEmployee")
);
const AllExhibitorEmployees = lazy(
  () => import("../../pages/employees/AllExhibitorEmployees")
);

const Speakers = lazy(() => import("../../pages/speakers/Speakers"));
const AddSpeaker = lazy(() => import("../../pages/speakers/AddSpeaker"));
const EditSpeaker = lazy(() => import("../../pages/speakers/EditSpeaker"));

const Judges = lazy(() => import("../../pages/judges/Judges"));
const AddJudge = lazy(() => import("../../pages/judges/AddJudge"));
const EditJudge = lazy(() => import("../../pages/judges/EditJudge"));
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoutes>
        {/* 1 for super admin , 2 for organizer  */}
        <AllowedTo allowedTo={[1, 2]}>
          <MainLayout />
        </AllowedTo>
      </ProtectedRoutes>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      // users
      {
        path: "users",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <Users />
              </Suspense>
            ),
          },
          {
            path: "add",
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<MagnifyLoader />}>
                    {/* <AddUser /> */}
                    <></>
                  </Suspense>
                ),
              },
              {
                path: "customer",
                element: (
                  <Suspense fallback={<MagnifyLoader />}>
                    <AddCustomerUserToEvent />
                  </Suspense>
                ),
              },
              {
                path: "exhibitor",
                element: (
                  <Suspense fallback={<MagnifyLoader />}>
                    <AddExhibitorUserToEvent />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      // categories
      {
        path: "categories",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <Categories />
              </Suspense>
            ),
          },
          {
            path: ":category_id",
            element: (
              <Suspense>
                <EditCategory />
              </Suspense>
            ),
          },
        ],
      },
      // banners
      {
        path: "banners",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Banners />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddBanner />
              </Suspense>
            ),
          },
          {
            path: ":banner_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditBanner />
              </Suspense>
            ),
          },
        ],
      },
      // events
      {
        path: "events",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Events />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddEvent />
              </Suspense>
            ),
          },
          {
            path: ":event_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditEvent />
              </Suspense>
            ),
          },
          {
            path: ":event_id/view",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <ViewEvent />
              </Suspense>
            ),
          },
        ],
      },
      // event exhibitors
      {
        path: "event-exhibitors",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EventExhibitors />
              </Suspense>
            ),
          },
        ],
      },
      // event customers
      {
        path: "event-customers",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EventCustomers />
              </Suspense>
            ),
          },
        ],
      },
      // event sessions
      {
        path: "event-sessions",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EventSessions />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddEventSession />
              </Suspense>
            ),
          },
          {
            path: ":session_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditEventSession />
              </Suspense>
            ),
          },
        ],
      },
      //event activities
      {
        path: "event-activities",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EventActivities />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddEventActivity />
              </Suspense>
            ),
          },
          {
            path: ":activity_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditEventActivity />
              </Suspense>
            ),
          },
        ],
      },
      // speakers
      {
        path: "speakers",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Speakers />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddSpeaker />
              </Suspense>
            ),
          },
          {
            path: ":speaker_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditSpeaker />
              </Suspense>
            ),
          },
        ],
      },
      // judges
      {
        path: "judges",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Judges />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddJudge />
              </Suspense>
            ),
          },
          {
            path: ":judge_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditJudge />
              </Suspense>
            ),
          },
        ],
      },
      //sponsor
      {
        path: "sponsors",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Sponsors />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <AddSponsor />
              </Suspense>
            ),
          },
          {
            path: ":sponsor_id",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EditSponsor />
              </Suspense>
            ),
          },
        ],
      },
      // notificaitons
      {
        path: "notifications",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Notifications />
              </Suspense>
            ),
          },
          {
            path: "push",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <PushNotification />
              </Suspense>
            ),
          },
        ],
      },
      // settings
      {
        path: "settings",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <Settings />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  // exhibitor pages
  {
    path: "/exhibitor",
    element: (
      <ProtectedRoutes>
        <AllowedTo allowedTo={[1, 3]}>
          <MainLayout />
        </AllowedTo>
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "events",
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <ExhibitorEvents />
              </Suspense>
            ),
          },
          {
            path: "customers",
            element: (
              <Suspense fallback={<MagnifyLoader />}>
                <EventCustomersForExhibitor />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "events/available",
        element: (
          <Suspense fallback={<MagnifyLoader />}>
            <AvailableEvents />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "employees",
    element: (
      <ProtectedRoutes>
        {/* 1 for super admin , 2 for organizer  */}
        <AllowedTo allowedTo={[1, 2, 3]}>
          <MainLayout />
        </AllowedTo>
      </ProtectedRoutes>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<MagnifyLoader />}>
            <ExhibitorEmployees />
          </Suspense>
        ),
      },
      {
        path: "add",
        element: (
          <Suspense fallback={<MagnifyLoader />}>
            <AddEmployeeUserToEventExhibitor />
          </Suspense>
        ),
      },
      {
        path: ":employee_id",
        element: (
          <Suspense fallback={<MagnifyLoader />}>
            <EditExhibitorEmployee />
          </Suspense>
        ),
      },
      {
        path: "all",
        element: (
          <Suspense fallback={<MagnifyLoader />}>
            <AllExhibitorEmployees />
          </Suspense>
        ),
      },
    ],
  },
  // auth
  {
    path: "/auth",
    children: [
      { path: "login", element: <Login /> },
      { path: "signup", element: <Signup /> },
    ],
  },
  // public for users
  {
    path: "/event-registration/:event_id",
    element: (
      <Suspense fallback={<MagnifyLoader />}>
        <EventRegistration />
      </Suspense>
    ),
  },
]);
