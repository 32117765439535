import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import NoItems from "../alerts/NoItems";
type Props = {
  events: {
    id: string;
    img: string;
    name: string;
    total_sponsors: number;
    total_exhibitors: number;
    total_customers: number;
  }[];
};

const EventsStatsList = ({ events }: Props) => {
  return (
    <Stack direction="row" gap="16px" flexWrap="wrap" justifyContent="center">
      {events?.length === 0 || events === null ? (
        <NoItems />
      ) : (
        events.map((event) => (
          <Stack
            key={event.id}
            width="290px"
            boxShadow={3}
            p={"16px"}
            borderRadius={1}
            sx={{
              background: `linear-gradient(#ffffffcc,#ffffff),url(${event.img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
              my={2}
            >
              {event.name}
            </Typography>
            <Stack
              direction="row"
              gap="8px"
              justifyContent="space-between"
              // divider={<Divider orientation="vertical" flexItem />}
            >
              <EventStatsCol label="عدد الرعاة" value={event.total_sponsors} />
              <EventStatsCol
                label="عدد العارضين"
                value={event.total_exhibitors}
              />
              <EventStatsCol label="عدد الزوار" value={event.total_customers} />
            </Stack>
          </Stack>
        ))
      )}
    </Stack>
  );
};

export default EventsStatsList;

const EventStatsCol = ({ label, value }: { label: string; value: number }) => {
  return (
    <Stack>
      <Typography
        variant="body2"
        fontSize="12px"
        textAlign="center"
        color="#444"
      >
        {label}
      </Typography>
      <Typography variant="h6" fontWeight="700" textAlign="center">
        {value}
      </Typography>
    </Stack>
  );
};
