import React from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import { Stack } from "@mui/material";
type Props = {};

const MagnifyLoader = (props: Props) => {
  return (
    <MagnifyingGlass
      visible={true}
      height="80"
      width="80"
      ariaLabel="magnifying-glass-loading"
      wrapperStyle={{ margin: "auto" }}
      wrapperClass="magnifying-glass-wrapper"
      glassColor="#c0efff"
      color="#e15b64"
    />
  );
};

export default MagnifyLoader;
