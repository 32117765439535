import React, { SyntheticEvent, useState } from "react";
// mui
import { Autocomplete, TextField } from "@mui/material";
// icons
import { BiLoaderAlt } from "react-icons/bi";
type Props<T extends { id?: string | number; name?: string }> = {
  options: T[];
  loading: boolean;
  isMultiValue?: boolean;
  onChange?: any;
  label: string;
  isOptionEqual?: (option: any, value: any) => any;
  defaultValue?: any;
};

const DynamicAutoComplete = <
  T extends { id?: string | number; name?: string },
>({
  options,
  loading,
  onChange,
  isMultiValue = false,
  label,
  isOptionEqual,
  defaultValue,
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Autocomplete
        sx={{ width: "100%", backgroundColor: "#fff" }}
        multiple={isMultiValue}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={
          isOptionEqual
            ? isOptionEqual
            : (option, value) => option?.id === value?.id
        }
        getOptionLabel={(option) => (option?.name ? option?.name : "")}
        options={options}
        loading={loading}
        defaultValue={defaultValue}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <BiLoaderAlt color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default DynamicAutoComplete;
