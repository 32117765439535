import { useSelector } from "react-redux";
import { getStatistics } from "../../apiCalls/statistics/getStatistics";
import PageLayout from "../../components/layout/PageLayout";
import PageTitle from "../../components/titles/PageTitle";
import { UserSliceState } from "../../types/types";
import { useQuery } from "react-query";
import MagnifyLoader from "../../components/loader/MagnifyLoader";
import EventsStatsList from "../../components/lists/EventsStatsList";
const Home = () => {
  const userToken = useSelector(
    (state: UserSliceState) => state.user.userToken
  );
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["getStatistics"],
    queryFn: () => getStatistics(userToken),
  });
  console.log("stats", data);
  return (
    <PageLayout>
      <PageTitle title="الصفحة الرئيسية" />
      {isLoading ? <MagnifyLoader /> : <EventsStatsList events={data.events} />}
    </PageLayout>
  );
};

export default Home;
