import { UseFormSetError } from "react-hook-form";

export const setFieldErrors = (
  errors: [{ [key: string]: "string" }],
  setError: UseFormSetError<any>
) => {
  for (let key in errors) {
    setError(key as any, { message: errors[key].toString() });
  }
  // return 0;
};
