export const valuesToFormData = <T>(values: T) => {
  const formData = new FormData();

  for (let key in values) {
    if (Array.isArray(values[key])) {
      (values[key] as any) = JSON.stringify(values[key]);
    } else if (isPlainObject(values[key]))
      (values[key] as any) = JSON.stringify(values[key]);

    formData.append(key, values[key] as any);
  }
  return formData;
};

function isPlainObject(variable: any) {
  return (
    variable !== null &&
    typeof variable === "object" &&
    !Array.isArray(variable) &&
    !(variable instanceof File)
  );
}
