import fetching from "../../utils/fetching/fetching";

export const getAppLogo = () => {
  return fetching(`/settings?q=app_logo`, "GET", {}, null, false)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.status) {
        throw data;
      }
      return data;
    });
};
