import React, { useState } from "react";
// r router
import { useNavigate } from "react-router";
// r redux
import { useSelector, useDispatch } from "react-redux";
// mui
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// components
import ConfirmationAlert from "../alerts/ConfirmationAlert";
import DialogWrapper from "../dialogs/DialogWrapper";
// fetch
import fetching from "../../utils/fetching/fetching";
// alerts
import fireAlert from "../../utils/alerts/fireAlert";
// icons
import { IoIosLogOut } from "react-icons/io";
// redux action
import {
  removeUserToken,
  removeUserData,
} from "../../redux/features/user/userSlice";

// types
import { UserSliceState } from "../../types/types";

type Props = {};

const Logout = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector(
    (state: UserSliceState) => state.user.userToken
  );
  const [open, setOpen] = useState(false);
  const logoutUser = async () => {
    setOpen(false);
    fetching(`/logout`, "GET", {
      Authorization: `Beaer ${userToken}`,
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(removeUserToken());
        dispatch(removeUserData());
        fireAlert("تم تسجيل الخروج بنجاح", "success");
        navigate("/auth/login");
      })
      .catch((error) => {
        dispatch(removeUserToken());
        dispatch(removeUserData());
        fireAlert("تم تسجيل الخروج بنجاح", "success");
        navigate("/auth/login");
      });
  };
  return (
    <>
      <ListItem disablePadding sx={{ color: "white" }}>
        <ListItemButton component={Button} onClick={() => setOpen(true)}>
          <ListItemIcon
            sx={{ fontSize: "22px", minWidth: "32px", color: "inherit" }}
          >
            <IoIosLogOut />
          </ListItemIcon>
          <ListItemText primary={"تسجيل الخروج"} />
        </ListItemButton>
      </ListItem>
      <DialogWrapper open={open} setOpen={setOpen}>
        <ConfirmationAlert
          msg="هل انت متاكد انك تريد تسجيل الخروج"
          confirmFn={logoutUser}
          open={open}
          setOpen={setOpen}
          confirmMsg="تسجيل الخروج"
        />
      </DialogWrapper>
    </>
  );
};

export default Logout;
