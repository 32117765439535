import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { Dialog, DialogContent } from "@mui/material";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
};

const DialogWrapper = ({ open, setOpen, children }: Props) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
