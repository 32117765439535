import React, { useState } from "react";
// react router
import { useSearchParams, useNavigate } from "react-router-dom";
// react redux
import { useSelector, useDispatch } from "react-redux";
// components
import PageLayout from "../../components/layout/PageLayout";
import PageTitle from "../../components/titles/PageTitle";
import InputField from "../../components/formComponents/InputField";
import UploadImage from "../../components/formComponents/UploadImage";
import SubmitButton from "../../components/formComponents/SubmitButton";
import DynamicAutoComplete from "../../components/select/DynamicAutoComplete";
// fetching
import fetching from "../../utils/fetching/fetching";
import { fetchingError } from "../../utils/fetching/fetchingError";
import { useQuery, useQueryClient } from "react-query";
import { getPublicCategories } from "../../apiCalls/categories/getPublicCategories";
// mui
import { Stack } from "@mui/material";
// reach-hook-form - schema
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addEmployeeUserSchema } from "../../schemes/users/AddEmployeeUserSchema";
// utils
import fireAlert from "../../utils/alerts/fireAlert";
// types
import { UserSliceState } from "../../types/types";
import { valuesToFormData } from "../../utils/fetching/appendValueToFormData";
type FormValues = {
  img: File | null;
  name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  job_description?: { id: string };
  event_exhibitor?: { id: string };
};
const AddEmployeeUserToEventExhibitor = () => {
  const navigate = useNavigate();
  const userToken = useSelector(
    (state: UserSliceState) => state.user.userToken
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const [searchParams, _] = useSearchParams();
  const event_exhibitor_id = searchParams.get("event_exhibitor_id") || "";
  const event_name = searchParams.get("event_name") || "";
  const event_id = searchParams.get("event_id") || "";
  const callbackUrl = `/employees?event_exhibitor_id=${event_exhibitor_id}&event_name=${event_name}&event_id=${event_id}`;
  const {
    data: categories,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["getJobDescriptionCategories"],
    queryFn: () =>
      getPublicCategories(
        `?page=1&filters=super_parent_id:${process.env.REACT_APP_JOB_DESC_CATEGORY_ID}&all`
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setError,
    setValue,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      img: null,
      name: "",
      email: "",
      phone: "",
      password: "password",
      password_confirmation: "password",
      job_description: { id: "" },
      event_exhibitor: { id: event_exhibitor_id },
    },
    resolver: yupResolver<any>(addEmployeeUserSchema),
    mode: "onSubmit",
  });
  const submitting: SubmitHandler<FormValues> = async (values) => {
    const formData = valuesToFormData(values);
    setIsSubmitting(true);
    fetching(
      `/event-exhibitor-employees/registration`,
      "POST",
      {
        Authorization: `Bearer ${userToken}`,
      },
      formData
    )
      .then((res) => res.json())
      .then((data) => {
        setIsSubmitting(false);
        if (data.id) {
          // navigate(callbackUrl);
          navigate(-1);
          return;
        }
        fetchingError(data, setError);
      })
      .catch((error) => fireAlert(error.toString(), "error"));
  };

  return (
    <PageLayout>
      <PageTitle title={`اضافة موظف`} />
      <Stack
        component="form"
        gap="16px"
        maxWidth="800px"
        width="100%"
        margin="0 auto"
        mt={4}
        onSubmit={handleSubmit(submitting)}
      >
        <UploadImage
          setValue={setValue}
          trigger={trigger}
          errorMessage={errors?.img?.message}
          height="200px"
          imgAspectRatio="1/1"
          name="img"
        />
        <InputField
          errorMessage={errors?.name?.message}
          name="name"
          type="text"
          label={"الاسم"}
          register={{ ...register("name") }}
        />

        <InputField
          errorMessage={errors?.email?.message}
          name="email"
          type="email"
          label={"البريد الالكتروني"}
          register={{ ...register("email") }}
        />

        <InputField
          errorMessage={errors?.phone?.message}
          name="phone"
          type="tel"
          label={"رقم الهاتف"}
          register={{ ...register("phone") }}
        />
        <DynamicAutoComplete
          options={isLoading ? [] : categories.data}
          loading={isLoading}
          isMultiValue={false}
          onChange={(e: any, newValue: any) =>
            setValue(`job_description`, { id: newValue.id })
          }
          label="صفة العمل"
        />
        <SubmitButton label="انشاء" disabled={isSubmitting} />
      </Stack>
    </PageLayout>
  );
};

export default AddEmployeeUserToEventExhibitor;
