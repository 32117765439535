import { Avatar } from "@mui/material";

import { useQuery } from "react-query";
import { getAppLogo } from "../../apiCalls/settings/getAppLogo";

type Props = {};

const AppLogo = (props: Props) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["getAppLogo"],
    queryFn: () => getAppLogo(),
  });
  return (
    <Avatar
      src={isLoading ? "" : data?.data[0]?.value || ""}
      alt="logo"
      variant="rounded"
      sx={{
        width: "150px",
        height: "150px",
        background: "#ffffff",
        margin: "0 auto",
      }}
    />
    // <Stack py={2} mx="auto">
    //   <Typography
    //     variant="body1"
    //     color="#fff"
    //     fontWeight="bold"
    //     display="flex"
    //     gap="8px"
    //     alignItems="center"
    //   >
    //     <FaRegUserCircle size={32} />
    //     {userData.email}
    //   </Typography>
    // </Stack>
  );
};

export default AppLogo;
